import { type Transition, motion } from "framer-motion";
import { type HTMLAttributes } from "react";

export default function AnimatedFade({
  transition = { duration: 1 },
  className,
  children,
}: {
  transition?: Transition;
} & HTMLAttributes<HTMLDivElement>): JSX.Element {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={transition}
      className={className}
    >
      {children}
    </motion.div>
  );
}
