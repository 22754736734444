import Spinner from "@/components/Spinner";
import AnimatedFade from "@/components/Transition/AnimatedFade";
import { Suspense, forwardRef } from "react";
import { useLocation, useOutlet } from "react-router-dom";

const AnimatedOutlet = forwardRef(() => {
  const outlet = useOutlet();
  const location = useLocation();

  return (
    <Suspense fallback={<Spinner />} key={location.pathname}>
      <AnimatedFade>{outlet}</AnimatedFade>
    </Suspense>
  );
});

AnimatedOutlet.displayName = "AnimatedOutlet";
export default AnimatedOutlet;
