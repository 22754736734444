import LanguageChanger from "@/components/LanguageChanger";
import ThemeChanger from "@/components/ThemeChanger";
import AnimatedOutlet from "@/components/Transition/AnimatedOutlet";
import { Navbar } from "react-daisyui";

export default function MasterLayout(): JSX.Element {
  return (
    <>
      <Navbar className="fixed justify-end">
        <LanguageChanger className="mr-1" />
        <ThemeChanger className="pr-3" />
      </Navbar>

      <AnimatedOutlet />
    </>
  );
}
