import { Puff } from "react-loader-spinner";

interface SpinnerProps {
  visible?: boolean;
  wrapperClass?: string;
}

export default function Spinner(props: SpinnerProps): JSX.Element {
  return (
    <div className="flex h-screen">
      <Puff
        radius="1"
        width="46"
        height="46"
        visible={true}
        color="#3661AB"
        wrapperClass="mx-auto mt-80"
        ariaLabel="tail-spin-loader"
        {...props}
      />
    </div>
  );
}
